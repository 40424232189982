import { useQuery } from "react-query";
import { getTickets } from '../api/ticketsApi';
import { FA_DELETE_TICKET, FA_GET_TICKET } from '../utils/constants';
import '../styles/Tickets.css';
import PaginatedTicketList from "../features/tickets/components/PaginatedTicketList";

const ClosedTickets = () => {

    //Fetch
    const {data:tickets, isLoading} = useQuery({
        queryKey: ["closedTickets"],
        queryFn: getTickets,
        select: tickets => tickets.filter(ticket => ticket.status === "closed") 
    });
    //Filter

    const functions = [
        {
            text: "Ver ticket",
            loadingComment:"Fetching ticket",
            action: FA_GET_TICKET,
        },
        {
            text: "Eliminar ticket",
            loadingComment:"Deleting ticket",
            action: FA_DELETE_TICKET,
        },
    ]

    return ( 
        <div>
            <h1>Tickets cerrados</h1>
            <div className="ticket-container">
                {isLoading && <p>Loading...</p>}
                {tickets && tickets.length > 0 ?
                    <PaginatedTicketList 
                        tickets={tickets} 
                        functions={functions}
                        query={"closedTickets"}
                        displaySolution={true}
                        pagination={true}
                    />
                    :
                    <div>No hay elementos todavía</div>
                 }
            </div>
        </div>
     );
}
 
export default ClosedTickets;