import { useState } from "react";
import { BsFillEyeFill,  BsFillEyeSlashFill} from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import { IoMdTrash, IoMdCreate } from "react-icons/io";
import { useMutation, useQueryClient } from "react-query";
import { deleteRequest } from "../../../api/requestsApi";
import ModalWrapper from "../../../components/ModalWrapper";
import useModal from "../../../hooks/useModal";
import { formaShortDate, formatDate } from "../../../utils/dateFormatter";
import "../styles/QuestionList.css";
import QuestionFormModal from "./QuestionFormModal";

const QuestionList = ({requests}) => {

    //States
    const [showAnswer, setShowAnswer] = useState(false);
    const [newRequest, setNewRequest] = useState(false)

    const [openRequestModal, toggleRequestModal] = useModal();

    //Query client
    const queryClient = useQueryClient();
    
    //Mutation
    const deleteRequestMutation = useMutation({
        mutationFn: deleteRequest,
        onSuccess: () => {
            console.log("Request deleted");
            queryClient.invalidateQueries("requests");
        }
    })

    //Handlers
    const handleDelete = (requestId) => {
        deleteRequestMutation.mutate(requestId);
    }
    
    return ( 


        <>
            {requests.map((request) => (
                <tr key={request._id} className="request">
                    <td>
                        <div className="request--request-statement">
                            <p className="request--statement">{request.requestStatement}</p>
                        </div>
                    </td>
                    <td>{request.learner}</td>
                    <td>{ formaShortDate(request.date)} </td>
                    <td>{request.tool}</td>
                    <td>{request.solutionType}</td>
                    <td>{request.comment !== null 
                        ? request.comment !== undefined
                            ? request.comment 
                            : 'No hay comentarios' 
                        : 'No hay comentarios' }</td>
                    <td>
                        <div className="request-actions">
                            {/* <button className="edit">Editar</button> */}
                            <button className="delete" onClick={() => handleDelete(request._id)}>Borrar</button>
                        </div>
                    </td>
                </tr>
            ))}

            

            <ModalWrapper isShowing={openRequestModal} closeModal={toggleRequestModal}>
                <QuestionFormModal 
                    toggleModal={toggleRequestModal}
                /> 
            </ModalWrapper>


            {/* Absolute */}
            <div className="absolute">
                <button className="new-ticket" onClick={ ()=> toggleRequestModal() }><FaPlus /> Nueva solicitud</button>
            </div>
        </>
     );
}
 
export default QuestionList;