import { useEffect, useState } from 'react';
import FunctionButtons from './FunctionButtons';
import axios from 'axios';
import { IoCloseSharp } from "react-icons/io5";
import { formatDate } from '../../../utils/dateFormatter';
import { STATUS_IN_PROG } from '../../../utils/constants';
import { useMutation, useQueryClient } from 'react-query';
import { patchTicket } from '../../../api/ticketsApi';

const DetailModalContent = ({ticketId, closeModal, functions, handleFunction, handleDelete, ticketQuery, displaySolution}) => {

    //Fetching the ticket to show
    const [ticket, setTicket] = useState(null);
    useEffect(()=>{
        const fetchTicket = async() => {
            const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}/tickets/${ticketId}`);
            if(data){
                setTicket(data);
            }
          }
          
          fetchTicket();
    },[ticketId]);

    const queryClient = useQueryClient();

    const updateTicketMutation = useMutation({
        mutationFn: patchTicket,
        onSuccess: () => {
            console.log("Ticket updated");
            queryClient.invalidateQueries(ticketQuery);
            //"tickets" -> Debería ser dinámico para probar el refetch
        }
    })

    //FORM STATES
    const [solution, setSolution] = useState('');
    const [solutionAlt, setSolutionAlt] = useState('');
    const [solutionComment, setSolutionComment] = useState('');

    

    const writeSolution = (e) => {
        setSolution(e.target.value);
        setSolutionAlt('');
    }

    const handleSolution = (e) => {
        e.target.value !== "Otro" 
        ? setSolution(e.target.value)
        : writeSolution(e)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        updateTicketMutation.mutate({
            ...ticket,
            solution: solutionAlt !== '' ? solutionAlt: solution,
            solution_comment: solutionComment !== '' ? solutionComment : null
        });
    }

    return ( 
        <>

            {ticket !== null 
                ? <div className="modal-container" onClick={e => e.stopPropagation()}>
                    <div className="modal--header">
                            <div className="header--data">
                                <h1>Ticket</h1>
                                <small>Folio: <span>{ticket._id}</span></small>
                            </div>
                            <div className="header--button">
                                <IoCloseSharp onClick={closeModal}/>
                            </div>
                        </div>
                        <div className="modal--content">
                            {/* Tags */}
                            <div className="ticket--tags">
                                <small>Tags</small>
                                <div className="tags">
                                    <div className="tag--data" data-status={ticket.status}>Estatus: <span>{ticket.status}</span></div>
                                    <div className="tag--data" data-priority={ticket.priority}>Prioridad: <span>{ticket.priority}</span></div>
                                    <div className="tag--data" data-risk={ticket.risk}>Riesgo: <span>{ticket.risk}</span></div>
                                    {ticket.origin && <div className="tag--data" data-origin={ticket.origin}>Origen: <span>{ticket.origin}</span></div>}
                                </div>
                            </div>
                            {/* Request */}
                            <div className="ticket--request">
                                <small>Solicitud</small>
                                <p>{ticket.request} <span>{ticket.added_resource && ticket.added_resource}</span></p>
                            </div>
                            {/* Dates */}
                            <div className="ticket--dates">
                                <small>Fechas a considerar</small>
                                <p className="data--value">Ventana de tiempo: <span>{ticket.time_window}</span></p>
                                {ticket.createdAt && <p className="data--value">Fecha de solicitud: <span>{formatDate(ticket.date)}</span></p>}
                            </div>
                            {/* Actors */}
                            <div className="ticket--actors">
                                <small>Personas involucradas</small>
                                {/* Required */}
                                <p className="data--value">Responsable: <span>{ticket.responsible}</span></p>
                                {/* Optional */}
                                {ticket.requester && <p className="data--value">Solicitante: <span>{ticket.requester}</span></p>}
                                {ticket.learner && <p className="data--value">Aprendedor: <span>{ticket.learner}</span></p>}
                            </div>
                            {/* Solution */}
                            {displaySolution
                                ?
                                <div className="ticket--solution">
                                    <small>Respuesta</small>
                                    {ticket.solution !== null 
                                        ? <p>{ticket.solution}. {ticket.solution_comment}</p>
                                        : <>
                                            {/* Form */}
                                            <form className='form--solution' onSubmit={handleSubmit}>
                                                <div className="form-input--solution">
                                                    <label htmlFor="solution">Solución</label>
                                                    <select 
                                                        name="solution"
                                                        id="solution"
                                                        value={solution}
                                                        onChange={(e) => handleSolution(e)}
                                                    >
                                                        <option>Selecciona una opción</option>
                                                        <option value="Modificación en Discord">Modificación en Discord</option>
                                                        <option value="Modificación en Plataforma">Modificación en Plataforma</option>
                                                        <option value="Escalamiento">Escalamiento</option>
                                                        <option value="Otro">Otro</option>
                                                    </select>
                                                </div>
                                                {solution === "Otro" 
                                                    ? <div className="form-input--solution">
                                                        <label htmlFor="alt-solution">Escribe la solución</label>
                                                        <input 
                                                            type="text"
                                                            name="alt-solution"
                                                            id="alt-solution"
                                                            value={solutionAlt}
                                                            onChange={(e) => setSolutionAlt(e.target.value)}
                                                        />
                                                        
                                            
                                                    </div>
                                                    : null
                                                }
                                                <div className="form-input--solution">
                                                        <label htmlFor="solution-comment">Comentarios</label>
                                                        <textarea
                                                            name="solution-comment"
                                                            id="solution-comment"
                                                            value={solutionComment}
                                                            onChange={(e) => setSolutionComment(e.target.value)}
                                                        />
                                                </div>
                                                <button className='solution--button' type="submit">Registrar solución</button>
                                            </form>
                                        </>
                                    } 
                                </div>
                                : null
                            }
                        </div>
                        <div className="modal--footer">
                            {
                                ticket.status === STATUS_IN_PROG 
                                ? null
                                : <FunctionButtons 
                                    functions={functions}
                                    handleFunction={handleFunction}
                                    handleDelete={handleDelete}
                                    ticket={ticket}
                                    />
                            }
                            
                        </div>
                </div>
                : <div>No existe el ticket</div>
            }
        </>
     );
}
 
export default DetailModalContent;