import moment from "moment";
import 'moment/locale/es';

export const formatDate = (date) => {
    moment.locale("es");
    const formattedDate = moment(date).format('DD [de] MMMM [de] YYYY - h:mm a');
    return formattedDate;
}

export const formaShortDate = (date) => {
    moment.locale("es");
    const formattedDate = moment(date).add(1, "day").format('DD-MM-YYYY'); // El add puede que se quite
    return formattedDate;
}

export const formatDateToMongo = (date) => {
    moment.locale("es");
    const formattedDate = moment(date).add(1, "day").format('YYYY-MM-DD');
    return formattedDate;
}


