import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { IoCloseSharp } from "react-icons/io5";
import { FI_REQUEST_STATEMENT, FI_DATE, FI_COMMENT, FI_LEARNER } from "../../../utils/constants";
import { createRequest } from "../../../api/requestsApi";
import { formatDateToMongo } from "../../../utils/dateFormatter";

const QuestionFormModal = ({ toggleModal }) => {

    //States
    const [requestStatement, setRequestStatement] = useState("");
    const [learner, setLearner] = useState("");
    const [date, setDate] = useState("");
    const [tool, setTool] = useState("Discord");
    const [solutionType, setSolutionType] = useState("Respuesta directa");
    const [comment, setComment] = useState("");
    //Query client
    const queryClient = useQueryClient();
    //Mutation
    const createRequestMutation = useMutation({
        mutationFn: createRequest,
        onSuccess: () => {
            console.log("Request created");
            queryClient.invalidateQueries("requests");
        }
    })

    //Handlers
    const handleOnChange = (e, input) => {
        switch(input) {
            case FI_REQUEST_STATEMENT: 
                setRequestStatement(e.target.value);
                break;
            case FI_DATE: 
                setDate(formatDateToMongo(e.target.value));
                break;
            case FI_LEARNER: 
                setLearner(e.target.value);
                break;
            case FI_COMMENT: 
                setComment(e.target.value);
                break;
            default: break;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Submitting question...");

        const request = {
            requestStatement,
            learner, 
            date, 
            tool,
            solutionType,
            comment: comment !== null 
                ? comment !== "" 
                    ? comment
                    : null
                : null
        }

        console.log(request);
        createRequestMutation.mutate(request);

    }

    return ( 
        <div className="modal-container new-ticket" onClick={e => e.stopPropagation()}>
            <div className="modal--header">
                <div className="header--data">
                    <h1>Nueva solicitud</h1>
                </div>
                <div className="header--button">
                    <IoCloseSharp onClick={toggleModal}/>
                </div>
            </div>
            
            <div className="modal--content">

                <form onSubmit={(e) => handleSubmit(e)}>
                    {/* Request */}
                    {/* Tool */}
                    {/* Solution Type */}
                   
                    <div className="form-input">
                        <label htmlFor="requestStatement">Solicitud</label>
                        <textarea 
                            name="requestStatement"
                            id="requestStatement"
                            placeholder="Escribe la solicitud"
                            value={requestStatement}
                            onChange={e => handleOnChange(e, FI_REQUEST_STATEMENT)}
                    />
                    </div>

                    <div className="select-group">
                        {/* Learner */}
                        <div className="form-input">
                            <label htmlFor="requester">Aprendedor</label>
                            <input 
                                type="text" 
                                name="learner" 
                                id="learner" 
                                placeholder="Escribe el aprendedor"
                                value={learner}
                                onChange={e => handleOnChange(e, FI_LEARNER)}
                            />
                        </div>
                        {/* Date */}
                        <div className="form-input">
                            <label htmlFor="date">Fecha</label>
                            <input 
                                type="date" 
                                name="date" 
                                id="date" 
                                value={date}
                                onChange={e => handleOnChange(e, FI_DATE)}
                            />
                        </div>
                        
                    </div>

                    <div className="select-group">
                        {/* Tool */}
                        <div className="form-input">
                            <label htmlFor="tool">Herramienta impactada</label>
                            <select 
                                name="tool"
                                id="tool"
                                value={tool}
                                onChange={(e) => setTool(e.target.value)}
                            >
                                <option value="Discord">Discord</option>
                                <option value="Plataforma">Plataforma</option>
                                <option value="Home Público">Home Público</option>
                            </select>
                        </div>
                        {/* Solution Type */}
                        <div className="form-input">
                            <label htmlFor="solutionType">Tipo de solución</label>
                            <select 
                                name="solutionType"
                                id="solutionType"
                                value={solutionType}
                                onChange={(e) => setSolutionType(e.target.value)}
                            >
                                <option value="Respuesta directa">Respuesta directa</option>
                                <option value="Modificación en plataforma">Modificación en plataforma</option>
                                <option value="Modificación en Discord">Modificación en Discord</option>
                                <option value="Modificación en Home Público">Modificación en Home Público</option>
                                <option value="Modificación en base de datos">Modificación en base de datos</option>
                            </select>
                        </div>
                    </div>
                    {/* Comment */}
                    <div className="form-input">
                        <label htmlFor="comment">Comentario</label>
                        <textarea 
                            name="comment"
                            id="comment"
                            placeholder="Escribe tu comentario"
                            value={comment}
                            onChange={e => handleOnChange(e, FI_COMMENT)}
                    />
                    </div>

                    
                    <div className="modal--footer">
                        <div className="btn-container">
                            <button type='submit' className="function-btn">Guardar</button>
                            <button className="close-btn" onClick={toggleModal}>Cancelar</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
     );
}
 
export default QuestionFormModal;