import axios from "axios";

const requestsApi = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/questions`
})

// -- Get all questions --
export const getRequests = async () => {
    console.log(`Se están buscando los datos de las solicitudes`);
    try {
        const { data } = await requestsApi.get(`/`);
        return data;
    } catch (error){
        console.error(error);
    }
}

// -- Post a question --
export const createRequest = async (request) => {
    console.log("Se está agregando una solicitud");
    try {
        const { data } = await requestsApi.post("/", request);
        return data;
    } catch (error) {
        console.error(error);
    }
}

// -- Delete a request --
export const deleteRequest = async (requestId) => {
    try {
        const res = await requestsApi.delete(`/${requestId}`);
        if(res.status !== 204){
            console.error("No se pudo borrar el recurso");
        }
        console.log("El recurso ya fue eliminado");
    } catch (error) {
        console.error(error);
    }
}

export default requestsApi;