import { useMutation, useQueryClient } from "react-query";
// TODO: Change requests Route
import { deleteRequest } from "../../../api/requestsApi";
import { formatDate } from "../../../utils/dateFormatter";
import "../styles/QuestionRow.css";

const QuestionRow = ({requests}) => {

    //Query client
    const queryClient = useQueryClient();
    
    //Mutation
    const deleteRequestMutation = useMutation({
        mutationFn: deleteRequest,
        onSuccess: () => {
            console.log("Request deleted");
            queryClient.invalidateQueries("aiRequests");
        }
    })

    //Handlers
    const handleDelete = (requestId) => {
        deleteRequestMutation.mutate(requestId);
    }
    
    return ( 


        <>
            {requests.map((request, index) => (
                <tr key={index} className="request">
                    {/* Aprendedor */}
                    <td>{request.author_name}</td>
                    {/* Pregunta */}
                    <td>{request.message_content}</td>
                    {/* Respuesta del bot */}
                    <td>{request.bot_response}</td>
                    {/* Fecha */}
                    <td>{ formatDate(request.created_at)} </td>
                    {/* Actions */}
                    <td>
                        <div className="request-actions">
                            <button className="delete" onClick={() => handleDelete(request._id)}>Borrar</button>
                        </div>
                    </td>
                </tr>
            ))}
        </>
     );
}
 
export default QuestionRow;