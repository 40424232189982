import { createPortal } from "react-dom";
import "../styles/Modal.css";

const ModalWrapper = ({children, isShowing, closeModal}) => {
    if(!isShowing){
        return null;
    }
    return ( 
        <div className="overlay" onClick={closeModal}>
            {isShowing && createPortal(
                children,
                document.getElementById("modal"))}
        </div>
     );
}
 
export default ModalWrapper;