import {Bar} from 'react-chartjs-2';
import {Chart as ChartJs} from "chart.js/auto";


const BarChart = ( { chartData, horizontal } ) => {
    return <Bar data={chartData} options={
        {
            responsive: true,
            indexAxis: horizontal ? "y" : "x",
            plugins: {
                legend: {
                    display: false
                }
            }
        }
    }/>;
}
 
export default BarChart;