import { useState } from "react";
import { NavLink } from "react-router-dom";

const SubMenu = ({item}) => {

    const [isOpen, setIsOpen] = useState(null);

    const openSubMenu = () => setIsOpen(!isOpen);

    return ( 
        <>
            <div className="submenu--header" onClick={openSubMenu}>
                    <div className="link_text">{item.title}</div>
                    <div>
                        {item.subNav && isOpen 
                        ? item.iconOpened
                        : item.subNav 
                        ? item.iconClosed 
                        : null }
                    </div>
            </div>
            
            {isOpen && item.subNav.map((subMenuItem, index)=> (
                <NavLink key={index} to={subMenuItem.path} className="submenu--link" activeclassName="active">
                    {subMenuItem.icon}
                    <span className="link_text">{subMenuItem.title}</span>
                </NavLink>
            ))}
        </>
     );
}
 
export default SubMenu;