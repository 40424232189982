import { useQuery } from "react-query";
import QuestionList from "../features/questions/components/QuestionList";
import { getRequests } from "../api/requestsApi";
import "../styles/Questions.css";

const Questions = () => {

    //Fetch
    const {data: requests} = useQuery({
        queryKey:["requests"],
        queryFn: getRequests
    });

    return ( 
        <div>
            <h1>Solicitudes</h1>
            <div className="request-container">
            <table className="request-table">
                <thead>
                    <tr>
                        <th>Solicitud</th>
                        <th>Aprendedor</th>
                        <th>Fecha</th>
                        <th>Herramienta impactada</th>
                        <th>Tipo de solución</th>
                        <th>Comentario</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {requests?.length > 0 ?
                        <QuestionList requests={requests} />
                        :
                        <tr>
                            <td>No hay preguntas todavía</td>
                        </tr>
                    }
                </tbody>
            </table>
                
            </div>
        </div>
     );
}
 
export default Questions;