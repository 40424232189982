import "../styles/Pagination.css";

import { RiArrowLeftSFill, RiArrowRightSFill } from "react-icons/ri";

const Pagination = ({pages, currentPage, setCurrentPage, margin}) => {

    const onChangePage = (id) => {
        setCurrentPage(id);
    };

    let pageElements = [];

    for (let i = 0; i < pages; i++) {
      pageElements.push({index: i, pageId:i});
    }

    return ( 
        <ul className="Pagination">
            {/* Before */}
            <li
                onClick={ () => onChangePage( (currentPage - 1 > -1) ? (currentPage - 1 ) : currentPage) }
                className={`PaginationItem PaginationPrev ${(currentPage - 1 > -1) && 'canGoPrev'}`}
            >
                <RiArrowLeftSFill />
            </li>
            {/* Pages */}
            {pageElements.map((page, index) => (
                <li
                    onClick={ () => onChangePage(page.pageId) }
                    key={index}
                    className={"PaginationItem PaginationIndex" + (page.index === currentPage ? " active" : "")}
                    >
                    {index + 1}
                </li>
            ))}
            {/* After */}
            <li
                onClick={ () => onChangePage((currentPage + 1 < pages) ? (currentPage + 1 ) : currentPage)}
                className={`PaginationItem PaginationNext ${(currentPage + 1 < pages) && 'canGoNext'}`}
            >
                <RiArrowRightSFill />
            </li>
            

        </ul>
     );
}
 
export default Pagination;