import axios from "axios";

const ticketsApi = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/tickets`
})

// -- Get a single ticket
export const getTicket = async (ticketId) => {
    console.log(`Se están buscando los datos de: ${ticketId}`);
    try {
        const {data} = await ticketsApi.get(`/${ticketId}`);
        return data;
    } catch (error){
        console.error(error);
    }
}

// -- Get all tickets --
export const getTickets = async () => {
    console.log(`Se están buscando los datos de los tickets`);
    try {
        const { data } = await ticketsApi.get("/");
        return data;
    } catch (error){
        console.error(error);
    }
}

// -- Add a ticket --
export const createTicket = async (ticket) => {
    try {
       const {data} = await ticketsApi.post("/", ticket);
       return data;
    } catch(error) {
        console.error(error);
    }
}

// -- Delete a ticket --
export const deleteTicket = async (ticketId) => {
    //console.log(`Se mandó borrar el ticket con id: ${ticketId}`);
    try {
        const res = await ticketsApi.delete(`/${ticketId}`);
        if(res.status !== 204){
            console.error("No se pudo borrar el recurso");
        }
        console.log("El recurso ya fue eliminado");
    } catch (error) {
        console.error(error);
    }
}

// -- Update a ticket's state --
/*export const patchticket = async (ticketId, status) => {
    console.log(`Se mandó actualizar el ticket con id: ${ticketId}`);
    try {
        const {data} = await ticketsApi.patch(`/${ticketId}`, {status});
    } catch (error) {
        console.log(error);
    }
}*/

export const patchTicket = async (ticket) => {
    try {
        return await ticketsApi.patch(`/${ticket._id}`, ticket);
    } catch (error) {
        console.log(error);
    }
}

export default ticketsApi;

