import { menuData } from '../utils/menuData';
import SubMenu from './SubMenu';

const Sidebar = ({children}) => {
    
    return ( 
        <div className="container">
            <div className="sidebar">
                <div className="top_section">
                    <h1 className="logo">Soporte</h1>
                </div>
                {
                    menuData.map((item, index) => (
                        <SubMenu item={item} key={index} />
                    ))
                }
            </div>
            <main>{children}</main>
        </div>
     );
}
 
export default Sidebar;