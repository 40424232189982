import { useQuery } from "react-query";
import QuestionRow from "../features/aiRequests/components/QuestionRow";
import { getAIRequests } from "../api/sofiaApi";
import "../styles/AIRequests.css";

const AIRequest = () => {

    //Fetch
    const {data: aiRequests} = useQuery({
        queryKey:["aiRequests"],
        queryFn: getAIRequests
    });

    return ( 
        <div>
            <div className="title">
                <h1>Preguntas a sofIA</h1>
                <p>{aiRequests?.length} Interacciones</p>
            </div>
            <div className="container">
            <table className="request-table">
                <thead>
                    <tr>
                        <th>Aprendedor</th>
                        <th>Pregunta</th>
                        <th>Respuesta del bot</th>
                        <th>Fecha de creación</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {aiRequests?.length > 0 ?
                        <QuestionRow requests={aiRequests} />
                        :
                        <tr>
                            <td>No hay requests todavía</td>
                        </tr>
                    }
                </tbody>
            </table>
                
            </div>
        </div>
     );
}
 
export default AIRequest;