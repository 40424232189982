//Function Actions
export const FA_GET_TICKET = "Get ticket"; //Done
export const FA_ASSIGN_TICKET = "Assign ticket"; //Done
export const FA_BEGIN_TICKET = "Begin ticket"; //Done
export const FA_HOLD_TICKET = "Hold ticket"; // Done
export const FA_CONTINUE_TICKET = "Continue ticket"; //Done
export const FA_SOLVE_TICKET = "Solve ticket";
export const FA_ESCALATE_TICKET = "Escalate ticket";
export const FA_CLOSE_TICKET = "Close ticket"; //Done
export const FA_DELETE_TICKET = "Delete ticket"; //Done
//Ticket status
export const STATUS_CLOSED = "closed";
export const STATUS_OPEN = "open";
export const STATUS_IN_PROG = "prog";
export const STATUS_ON_HOLD = "hold";
export const STATUS_SOLVED = "solved";
export const STATUS_ESCALATED = "escalated";
//Ticket Form Inputs
export const FI_REQUEST = "request";
export const FI_TIME_WINDOW = "time_window";
export const FI_ADDED_RESOURCE = "added_resource";
export const FI_RESPONSIBLE = "responsible";
export const FI_REQUESTER = "resquester";
export const FI_LEARNER = "learner";
export const FI_ORIGIN = "origin";
export const FI_AREA = "area";
//Question Form Inputs
export const FI_REQUEST_STATEMENT = "requestStatement";
export const FI_DATE = "date";
export const FI_COMMENT = "comment";