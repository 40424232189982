import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import "../styles/FormModal.css";
import { IoCloseSharp } from "react-icons/io5";
import { createTicket } from '../../../api/ticketsApi';
import { FI_REQUEST, FI_LEARNER, FI_REQUESTER, FI_ADDED_RESOURCE, FI_ORIGIN, FI_AREA, FI_DATE } from '../../../utils/constants';
import { formatDateToMongo } from "../../../utils/dateFormatter";

const FormModalContent = ({query, closeModal}) => {

    const [request, setRequest] = useState('');
    const [responsible, setResponsible] = useState('Jesús Jiménez');
    const [risk, setRisk] = useState('bajo');
    const [priority, setPriority] = useState('baja');
    const [timeWindow, setTimeWindow] = useState("24 horas");
    const [date, setDate] = useState("");
    //Non-required
    const [learner, setLearner] = useState('');
    const [requester, setRequester] = useState('');
    const [addedResource, setAddedResource] = useState('');
    const [origin, setOrigin] = useState('');
    const [area, setArea] = useState('');
    //Advanced settings
    const [showAdvanced, setShowAdvanced] = useState(false);

    //Query Client
    const queryClient = useQueryClient();

    //Mutation
    const createTicketMutation = useMutation({
        mutationFn: createTicket,
        onSuccess: () => {
            console.log("Ticket successfuly added");
            queryClient.invalidateQueries(query);
        }
    })

    //Inputs
    const handleOnChange = (e, input) => {
        switch(input) {
            case FI_REQUEST: 
                setRequest(e.target.value);
                break;
            case FI_LEARNER: 
                setLearner(e.target.value);
                break;
            case FI_REQUESTER: 
                setRequester(e.target.value)
                break;
            case FI_ADDED_RESOURCE: 
                setAddedResource(e.target.value);
                break;
            case FI_DATE: 
                setDate(formatDateToMongo(e.target.value));
                break;
            case FI_AREA: 
                setArea(e.target.value);
                break;
            case FI_ORIGIN: 
                setOrigin(e.target.value);
                break;
            default:
                console.error("Error con handleOnChange");
                break;
        }

    }
    

    //Handler
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Se mandó agregar algo");

        const ticket = {
            request,
            responsible,
            status: "pending",
            risk,
            priority,
            time_window: timeWindow,
            date,
            learner: showAdvanced ? learner : null,
            requester: showAdvanced ? requester : null,
            added_resource: showAdvanced ? addedResource : null,
            origin, 
            solution: null,
            solution_comment:null
        }

        //Enviar al servidor (mutación)
        createTicketMutation.mutate(ticket);

        //Comprobación de resultados
        console.log(ticket);
        closeModal();

    }

    return ( 
        <div className="modal-container new-ticket" onClick={e => e.stopPropagation()}>
            <div className="modal--header">
                <div className="header--data">
                    <h1>Nuevo ticket</h1>
                </div>
                <div className="header--button">
                    <IoCloseSharp onClick={closeModal}/>
                </div>
            </div>
            {/* Show advenced settings */}
            <div className="condition-checkbox">
                <input 
                    type="checkbox"
                    name="show-resource"
                    id="show-resource"
                    checked={showAdvanced}
                    onChange={()=> setShowAdvanced(!showAdvanced)}
                />
                <label htmlFor="show-resource">Configuración avanzada</label>
            </div>
            <div className="modal--content">

                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="select-group">
                        {/* Risk ✅ */}
                        <div className="form-input">
                            <label htmlFor="risk">Riesgo</label>
                            <select 
                                name="risk"
                                id="risk"
                                value={risk}
                                onChange={(e) => setRisk(e.target.value)}
                            >
                                <option value="bajo">Bajo</option>
                                <option value="medio">Medio</option>
                                <option value="alto">Alto</option>
                            </select>
                        </div>
                        {/* Priority ✅ */}
                        <div className="form-input">
                            <label htmlFor="priority">Prioridad</label>
                            <select 
                                name="priority"
                                id="priority"
                                value={priority}
                                onChange={(e) => setPriority(e.target.value)}
                            >
                                <option value="baja">Baja</option>
                                <option value="media">Media</option>
                                <option value="alta">Alta</option>
                            </select>
                        </div>
                    </div>
                    <div className="input-group">
                        {/* -- Request -- */}
                        {/* - Simple Request - */}
                        <div className="form-input">
                            <label htmlFor="request">Solicitud</label>
                            <textarea 
                                name="request"
                                id="request"
                                placeholder="Escribe la solicitud"
                                value={request}
                                onChange={e => handleOnChange(e, FI_REQUEST)}
                            />
                        </div>
                        {showAdvanced && 
                            /* Added resource (optional) */
                            <div className="form-input">
                                <label htmlFor="addedResource">Recurso adicional (Opcional)</label>
                                <textarea 
                                    name="addedResource" 
                                    id="addedResource" 
                                    placeholder="Escribe el recurso adicional"
                                    value={addedResource}
                                    onChange={e => handleOnChange(e, FI_ADDED_RESOURCE)}
                                />
                            </div>
                        }
                        {/* - Alt Request - */}
                        <div className="select-group">
                            {/* Time window */}
                            <div className="form-input">
                                <label htmlFor="time-window">Ventana de tiempo</label>
                                <select 
                                    name="time-window"
                                    id="time-window"
                                    value={timeWindow}
                                    onChange={(e) => setTimeWindow(e.target.value)}
                                >
                                    <option value="24 horas">24 horas</option>
                                    <option value="48 horas">48 horas</option>
                                    <option value="indefinido">Indefinido</option>
                                </select>
                            </div>
                            {/* Responsible */}               
                            <div className="form-input">
                                <label htmlFor="responsible">Responsable</label>
                                <select 
                                    name="responsible"
                                    id="responsible"
                                    value={responsible}
                                    onChange={(e) => setResponsible(e.target.value)}
                                >
                                    <option value="Jesús Jiménez">Jesús Jiménez</option>
                                    <option value="Otro">Otro</option>
                                </select>
                            </div>
                            {/* Date */}
                            <div className="form-input">
                                <label htmlFor="date">Fecha</label>
                                <input 
                                    type="date" 
                                    name="date" 
                                    id="date" 
                                    value={date}
                                    onChange={e => handleOnChange(e, FI_DATE)}
                                />
                            </div>
                        </div>
                        
                    </div>
                    {/* Involucrados */}
                    <div className="input-group">
                        
                        {showAdvanced && 
                            <>
                                <div className="select-group">    
                                    {/* Area */}
                                    <div className="form-input">
                                        <label htmlFor="area">Área de impacto</label>
                                        <select 
                                            name="area"
                                            id="area"
                                            value={area}
                                            onChange={(e) => setArea(e.target.value)}
                                        >
                                            <option value="Operación">Operación</option>
                                            <option value="Captación">Captación</option>
                                            <option value="Comunidades">Comunidades</option>
                                        </select>
                                    </div>
                                    {/* Requester (optional) */}
                                    <div className="form-input">
                                        <label htmlFor="requester">Solicitante (Opcional)</label>
                                        <input 
                                            type="text" 
                                            name="requester" 
                                            id="requester" 
                                            placeholder="Escribe el solicitante"
                                            value={requester}
                                            onChange={e => handleOnChange(e, FI_REQUESTER)}
                                        />
                                    </div>
                                </div>
                                <div className="select-group">
                                    {/* Learner (optional) */}
                                    <div className="form-input">
                                        <label htmlFor="learner">Aprendedor (Opcional)</label>
                                        <input 
                                            type="text"
                                            name="learner"
                                            id="learner"
                                            placeholder="Escribe el recurso adicional" 
                                            value={learner}
                                            onChange={e => handleOnChange(e, FI_LEARNER)}
                                        />
                                    </div>
                                    {/* Learner (optional) */}
                                    <div className="form-input">
                                        <label htmlFor="origin">Origen (Opcional)</label>
                                        <input 
                                            type="text"
                                            name="origin"
                                            id="origin"
                                            placeholder="Escribe el origen" 
                                            value={origin}
                                            onChange={e => handleOnChange(e, FI_ORIGIN)}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    
                    <div className="modal--footer">
                        <div className="btn-container">
                            <button type='submit' className="function-btn">Guardar</button>
                            <button className="close-btn" onClick={closeModal}>Cancelar</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
     );
}
 
export default FormModalContent;