import {Doughnut} from 'react-chartjs-2';
import {Chart as ChartJs} from "chart.js/auto";


const PieChart = ( { chartData, legend } ) => {
    return <Doughnut data={chartData} options={
        {
            responsive: true,
            // maintainAspectRatio:true,
            plugins: {
                legend: {
                    display: legend
                }
            }
        }
    }/>;
}
 
export default PieChart;