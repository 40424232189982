import PieChart from "../features/dashboard/components/PieChart";
import "../styles/Dashboard.css";
import "../features/dashboard/styles/DashboardBlocks.css";
import BarChart from "../features/dashboard/components/BarChart";
import { useQuery } from "react-query";
import { getCount } from "../api/countApi";

const Dashboard = () => {

    const { data:count, isLoading, isError } = useQuery({
        queryKey:["countTotal"],
        queryFn: getCount,
        onSuccess: () => console.log("Se obtuvo la cuenta"),
        onError: () => console.log("Error al cargar")
    });

    // Solicitudes totales
    const total = count && count.totalCount.total;


    const totalRequestData = {
        labels: ["Nivel 1", "Nivel 2", "Nivel 3"],
        datasets: [{
            label: "Solicitudes",
            data: count && [count.totalCount.totalLevelOne, count.totalCount.totalLevelTwo, count.totalCount.totalLevelThree],
        },
        ]
    }

    // Solicitudes N1
    const toolsData = {
        labels: ["Discord", "Plataforma", "Home Público"],
        datasets: [{
            label: "Solicitudes",
            data: count && [count.toolCount.discord, count.toolCount.platform, count.toolCount.home],
            axis: "x"
        },
        ]
    }

    // Status
    const statusData = {
        labels: count && Object.keys(count.statusCount), // Ocupa corrección (valores estáticos)
        datasets: [{
            label: "Solicitudes",
            data: count && Object.values(count.statusCount),
            axis: "x"
        },
        ]
    }

    const historyData = {
        labels: count && count.requestHistory.labels, // TODO: Debería ser requestHistory.labels
        datasets: [
            {
                label: "Solicitudes N1",
                data: count && Object.values(count.requestHistory.levelOne)
            },
            {
                label: "Solicitudes N2",
                data: count && Object.values(count.requestHistory.levelTwo)
            },
            {
                label: "Solicitudes N3",
                data: count && Object.values(count.requestHistory.levelThree)
            }
        ]
    }

    const topRequests = [
        {
            count: 22,
            text: "El aprendedor no puede ver sus retos en plataforma"
        },
        {
            count: 17,
            text: "El aprendedor no puede ver sus retos en plataforma"
        },
        {
            count: 10,
            text: "El aprendedor no puede ver sus retos en plataforma"
        },
        {
            count: 7,
            text: "Bloque del correo de captación en Gmail y Front"
        },
    ]


    // Solicitudes N2

    // Historial de solicitudes
    
    // Solicitudes N2

    return ( 
        <>
            {isLoading && <p>Is Loading</p>}
            {isError && <p>Error</p>}
            {
                count && count !== undefined 
                    ? <div>
                        <h1 className="mb-1">Dashboard</h1>
                        <section className="top">
                            <div className="dashboard-block--element w-40 general">
                                <div className="numeralia mr-1">
                                    <p className="number">{total}</p>
                                    <p className="label">Solicitudes</p>
                                </div>
                                <div className="pie-chart">
                                    <PieChart chartData={totalRequestData} legend={false}/>
                                </div>
                            </div>
                            <div className="dashboard-block w-30">
                                <div className="dashboard-block--title">Solicitudes N1</div>
                                <div className="dashboard-block--element n1">
                                    <div className="bar-chart">
                                        <BarChart chartData={toolsData} horizontal={true}/>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-block w-30">
                                <div className="dashboard-block--title">Solicitudes N2</div>
                                <div className="dashboard-block--element n2">
                                    <div className="chart">
                                        <PieChart chartData={statusData} legend={false}/>
                                    </div>
                                </div>
                            </div>
                        
                            
                        </section>
                        <section className="bottom">
                            <div className="dashboard-block">
                                <div className="dashboard-block--title no-background">Historial de solicitudes</div>
                                <div className="dashboard-block--element">
                                    <div className="chart">
                                        <BarChart chartData={historyData} horizontal={false}/>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-block">
                                <div className="dashboard-block--title no-background">Solicitudes frecuentes</div>
                                <div className="dashboard-block--element">
                                    <div className="top-requests">
                                        {
                                            topRequests?.map((topRequest => (
                                                <div className="request">
                                                    <div className="request--count">{topRequest.count}</div>
                                                    <div className="request--text">{topRequest.text}</div>
                                                </div>
                                            )))
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </section>
                    
                    </div>
                    : <p>No hay datos</p>
            }
            
        </>
     );
}
 
export default Dashboard;