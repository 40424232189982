export const pointSort = (a, b) => {
    if( a.points === b.points ) {
        return a.date < b.date ? -1 
            : a.date > b.date ? 1
            : 0 //Default
    } else {
        return b.points < a.points ? -1 : 1; // Ordena de mayor a menor puntaje
    }
}

export const recentSort = (a, b) => a.date - b.date;