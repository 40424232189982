import axios from "axios";

const countApi = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/count`
})

// -- Get Count --
export const getCount = async () => {
    console.log(`Se están buscando los datos para el dashboard`);
    try {
        const { data } = await countApi.get(`/`);
        return data;
    } catch (error){
        console.error(error);
    }
}


export default countApi;