import './styles/App.css';
import SidebarAlt from './components/SidebarAlt';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import OpenTickets from './pages/OpenTickets';
import ClosedTickets from './pages/ClosedTickets';
import InProgTickets from './pages/InProgTickets';
import OnHoldTickets from './pages/OnHoldTickets';
import SolvedTickets from './pages/SolvedTickets';
import EscalatedTickets from './pages/EscalatedTickets';
import NewTickets from './pages/NewTickets';
import Tickets from './pages/Tickets';
import Questions from './pages/Questions';
import { useEffect } from 'react';
import Dashboard from './pages/Dashboard';
import AIRequest from './pages/aiRequests';

function App() {

  useEffect(() => {
    Notification.requestPermission();
  }, [])

  return (
    <div>
        <BrowserRouter>
          <SidebarAlt>
            <Routes>
                {/*N1*/}
                <Route path="/level/1/questions" element={<Questions />} />
                {/*N2*/}
                <Route path="/level/2/all" element={<Tickets showStatus={true}/>} />
                <Route path="/level/2/open" element={<OpenTickets />} />
                <Route path="/level/2/prog" element={<InProgTickets />} />
                <Route path="/level/2/hold" element={<OnHoldTickets />} />
                <Route path="/level/2/solved" element={<SolvedTickets />} />
                <Route path="/level/2/escalated" element={<EscalatedTickets />} />
                <Route path="/level/2/new" element={<NewTickets />} />
                <Route path="/level/2/closed" element={<ClosedTickets />} />
                {/*N3*/}
                {/* Agregar página de formato de correo de escalamiento N3 */}
                {/*Data Analysis*/}
                <Route path="/data/dashboard" element={<Dashboard />} />
                <Route path="/bot/sofia" element={<AIRequest />} />
            </Routes>
          </SidebarAlt>
        </BrowserRouter>
    </div>
  );
}

export default App;
