import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

export const menuData = [
    {
        title: "Nivel 1",
        path: "/level/1",
        icon: <AiIcons.AiFillHome />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav:[
            {
                title: "Preguntas",
                path: "/level/1/questions",
                icon: <IoIcons.IoIosPaper />
            }
        ]
    },
    {
        title: "Nivel 2",
        path: "/level/2",
        icon: <AiIcons.AiFillHome />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav:[
            {
                title: "Tickets",
                path: "/level/2/all",
                icon: <IoIcons.IoIosPaper />
            },
            //New Tickets
            {
                title: "Nuevos",
                path: "/level/2/new",
                icon: <IoIcons.IoIosPaper />
            },
            //Open Tickets
            {
                title: "Abiertos",
                path: "/level/2/open",
                icon: <IoIcons.IoIosPaper />
            },
            //InProg Tickets
            {
                title: "En progreso",
                path: "/level/2/prog",
                icon: <IoIcons.IoIosPaper />
            },
            //OnHold Tickets
            {
                title: "Retenidos",
                path: "/level/2/hold",
                icon: <IoIcons.IoIosPaper />
            },
            //Solved Tickets
            {
                title: "Resueltos",
                path: "/level/2/solved",
                icon: <IoIcons.IoIosPaper />
            },
            //Escalated Tickets
            {
                title: "Escalados",
                path: "/level/2/escalated",
                icon: <IoIcons.IoIosPaper />
            },
            //Closed Tickets
            {
                title: "Cerrados",
                path: "/level/2/closed",
                icon: <IoIcons.IoIosPaper />
            }
        ]
    },
    // {
    //     title: "Nivel 3",
    //     path: "/level/3",
    //     icon: <AiIcons.AiFillHome />,
    //     iconClosed: <RiIcons.RiArrowDownSFill />,
    //     iconOpened: <RiIcons.RiArrowUpSFill />,
    //     subNav:[
    //         {
    //             title: "Tickets",
    //             path: "/level/3/tickets",
    //             icon: <IoIcons.IoIosPaper />
    //         }
    //     ]
    // },
    {
        title: "Datos",
        path: "/data",
        icon: <AiIcons.AiFillHome />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav:[
            {
                title: "Dashboard",
                path: "/data/dashboard",
                icon: <IoIcons.IoIosPaper />
            },
        ]
    },
    {
        title: "Bots",
        path: "/bot",
        icon: <AiIcons.AiFillHome />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav:[
            {
                title: "sofIA",
                path: "/bot/sofia",
                icon: <IoIcons.IoIosPaper />
            },
            // {
            //     title: "Soporte",
            //     path: "/bot/support",
            //     icon: <IoIcons.IoIosPaper />
            // },
        ]
    },
]