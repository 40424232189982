import { FA_DELETE_TICKET } from "../../../utils/constants";
import "../styles/FunctionButton.css";

const FunctionButtons = ({functions, handleFunction, handleDelete, ticket}) => {
    return ( 
        <div className="function-buttons">
            {functions.map((func) => (
                func.action !== FA_DELETE_TICKET 
                    ? <button key={func.action} className="function" onClick={() => handleFunction(ticket, func)}>{func.text}</button>                                
                    : <button key={func.action} className="delete" onClick={() => handleDelete(ticket._id)}>{func.text}</button> 
                ))}
        </div>
     );
}
 
export default FunctionButtons;