import React from 'react';
import { useQuery } from "react-query";
import { getTickets } from '../api/ticketsApi';
import { FA_ASSIGN_TICKET, FA_DELETE_TICKET, STATUS_OPEN } from '../utils/constants';
import '../styles/Tickets.css';
import { pointSort } from '../utils/sorter';
import PaginatedTicketList from '../features/tickets/components/PaginatedTicketList';

const PendingTickets = () => {

    //Fetch
    const {data:tickets, isLoading} = useQuery({
        queryKey: ["pendingTickets"],
        queryFn: getTickets,
        select: tickets => tickets.filter(ticket => ticket.status === "pending").sort(pointSort)
    });

    const functions = [
        {
            text: "Asignar ticket",
            loadingComment:"Assigning ticket",
            action: FA_ASSIGN_TICKET,
            newStatus: STATUS_OPEN
        },
        
        {
            text: "Eliminar ticket",
            loadingComment:"Deleting ticket",
            action: FA_DELETE_TICKET,
        },
    ]

    return ( 
        <div>
            <h1>Tickets nuevos</h1>
            <div className="ticket-container">
                {isLoading && <p>Loading...</p>}
                {tickets && tickets.length > 0 ?
                    <PaginatedTicketList 
                        tickets={tickets} 
                        functions={functions}
                        query={"pendingTickets"}
                    />
                    :
                    <div>No hay elementos todavía</div>
                 }
            </div>
        </div>
     );
}
 
export default PendingTickets;