import { useQuery } from "react-query";
import PaginatedTicketList from "../features/tickets/components/PaginatedTicketList";
import { getTickets } from '../api/ticketsApi';
import { FA_DELETE_TICKET, FA_GET_TICKET } from '../utils/constants';
import '../styles/Tickets.css';

const Tickets = () => {

    const {data:tickets, isLoading} = useQuery({
        queryKey: ["tickets"],
        queryFn: getTickets
    });

    const functions = [
        {
            text: "Ver ticket",
            loadingComment:"Fetching ticket",
            action: FA_GET_TICKET,
        },
        {
            text: "Eliminar ticket",
            loadingComment:"Deleting ticket",
            action: FA_DELETE_TICKET,
        },
    ]

    return ( 
        <div>
            <h1>Tickets</h1>    
            <div className="ticket-container">
                {isLoading && <p>Loading...</p>}
                {tickets && tickets.length > 0 
                    ?  <PaginatedTicketList 
                            tickets={tickets}
                            functions={functions}
                            query={"tickets"} 
                            pagination={true}
                        />
                    : <div>No hay elementos todavía</div>
                 }
            </div>
        </div>
     );
}
 
export default Tickets;