import axios from "axios";

// TODO: Update url
const sofiaApi = axios.create({
    baseURL: `${process.env.REACT_APP_SOFIA_API}/`
})

// TODO: Agregar requests

// -- Get all messages --
export const getAIRequests = async () => {
    console.log(`Se están buscando los datos de las solicitudes`);
    try {
        const { data } = await sofiaApi.get(`/messages`);
        console.log(data.messages)
        return data.messages;
    } catch (error){
        console.error(error);
    }
}

export default sofiaApi;