import { useQuery } from "react-query";
import {getTickets} from "../api/ticketsApi.js";
import { FA_BEGIN_TICKET, FA_DELETE_TICKET, STATUS_IN_PROG } from '../utils/constants';
import '../styles/Tickets.css';
import PaginatedTicketList from "../features/tickets/components/PaginatedTicketList";

const OpenTickets = () => {

    //Fetch
    const {data:tickets, isLoading} = useQuery({
        queryKey:["openTickets"],
        queryFn: getTickets,
        select: tickets => tickets.filter(ticket => ticket.status === "open")
    });
    //Filter
    //const openTickets = tickets && tickets.filter(ticket => ticket.status === "open");
    
    const functions = [
        {
            text: "Iniciar ticket",
            loadingComment:"Beginning ticket",
            action: FA_BEGIN_TICKET,
            newStatus: STATUS_IN_PROG
        },
        
        {
            text: "Eliminar ticket",
            loadingComment:"Deleting ticket",
            action: FA_DELETE_TICKET,
        },
    ]

    return ( 
        <div>
            <h1>Tickets abiertos</h1>
            <div className="ticket-container">
                {isLoading && <p>Loading...</p>}
                {tickets && tickets.length > 0 ?
                    <PaginatedTicketList 
                        tickets={tickets} 
                        functions={functions}
                        query={"openTickets"}
                    />
                    :
                    <div>No hay elementos todavía</div>
                 }
            </div>
        </div>
     );
}
 
export default OpenTickets;