import React, { useEffect, useState } from 'react';
import '../styles/TicketCard.css';
import { FaInfoCircle, FaPlus } from 'react-icons/fa';
import { deleteTicket, patchTicket } from '../../../api/ticketsApi.js';
import { FA_GET_TICKET } from '../../../utils/constants';
import { useMutation, useQueryClient } from 'react-query';
import Pagination from '../../../components/Pagination';
import useModal from '../../../hooks/useModal';
import ModalWrapper from '../../../components/ModalWrapper';
import DetailModalContent from './DetailModalContent';
import FunctionButtons from './FunctionButtons';
import FormModalContent from './FormModalContent';

const PaginatedTicketList = ( { tickets, ticketQuery, functions, displaySolution, pagination } ) => {

    // --- States ---
    const [idToFetch, setIdToFetch] = useState(null);


    // --- Hooks ---
    const [openDetailModal, toggleDetailModal] = useModal();
    const [openFormModal, toggleFormModal] = useModal();
    const queryClient = useQueryClient();


    // -- Pagination ---
    const [currentPage, setCurrentPage] = useState(0);
    const [count, setCount] = useState(0);
    
    const offset = pagination ? 9 : tickets.length;
    
    useEffect(()=> {
        setCount(!!tickets ? tickets.length : 0);
    }, [tickets]);
    
    const start = currentPage * offset;
    const end =  (+currentPage+1) * offset;
   

    // --- Mutations ---
    const deleteTicketMutation = useMutation({
        mutationFn: deleteTicket,
        onSuccess: () => {
            console.log("Deleting ticket");
            //Falta probar con prop query
            queryClient.invalidateQueries(ticketQuery);
        }
    });

    const updateTicketMutation = useMutation({
        mutationFn: patchTicket,
        onSuccess: () => {
            console.log("Ticket updated");
            queryClient.invalidateQueries(ticketQuery);
            //"tickets" -> Debería ser dinámico para probar el refetch
        }
    })


    // --- Handlers ---
    const handleDelete = (ticketId) => {
        deleteTicketMutation.mutate(ticketId);
    }

    //New optimized function
    const handleFunction = (ticket, buttonFunction) => {
        console.log(buttonFunction.loadingComment);
        if(buttonFunction.action !== FA_GET_TICKET) {
            return updateTicketMutation.mutate({
                ...ticket,
                status: buttonFunction.newStatus
            });
        }
        return handleModal(ticket._id);
    }

    //Handl eModal
    const handleModal = (ticketId) => {
        console.log("Opening Modal");
        toggleDetailModal();
        setIdToFetch(ticketId);
    }
    

    return ( 
        <>
            <div className="tickets">
                {tickets?.slice(start, end).map((ticket, index) => (
                    <div key={ticket._id} className="ticket-card" data-priority={ticket.priority}>
                        <div className="ticket-card--header">
                            <div className="ticket-card--header--text">
                                <h3 className="ticket-card--id">Ticket {index+1}</h3>
                                <small>Folio: <span>{ticket._id}</span></small>
                                {/* <small>Puntos: <span>{ticket.points}</span></small> */}
                            </div>
                            {/* Details */}
                            <div className="ticket-card--header--button">
                                <FaInfoCircle onClick={() => handleModal(ticket._id)}/>
                            </div>
                        </div>
                        <div className="ticket-card--content">
                            <div className="ticket--request" >
                                <small>Solicitud</small>
                                <p>{ticket.request}</p>
                            </div>
                        </div>
                        <div className="ticket-card--footer">
                            { /* Buttons */ }
                            <FunctionButtons 
                                functions={functions}
                                handleFunction={handleFunction}
                                handleDelete={handleDelete}
                                ticket={ticket}
                            />
                        </div>
                    </div>
                ))}                
                {/* New modal implementation */}
                <ModalWrapper isShowing={openDetailModal} closeModal={toggleDetailModal}>
                    <DetailModalContent 
                        ticketId={idToFetch}
                        closeModal={toggleDetailModal}
                        functions={functions}
                        handleFunction={handleFunction}
                        handleDelete={handleDelete} 
                        ticketQuery={ticketQuery}
                        displaySolution={displaySolution}
                    />
                </ModalWrapper>
                <ModalWrapper isShowing={openFormModal} closeModal={toggleFormModal}>
                    <FormModalContent 
                        closeModal={toggleFormModal}
                        query={ticketQuery}
                    />
                </ModalWrapper>

                {/* Absolute */}
                <div className="absolute">
                    <button className="new-ticket" onClick={ ()=> toggleFormModal() }><FaPlus /> Nuevo Ticket</button>
                </div>

            </div>
            {pagination 
                ? <Pagination 
                    pages={Math.ceil(count / offset)}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    margin={5}
                />
                : null
            }
            
        </>
     );
}

export default PaginatedTicketList;